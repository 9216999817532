.navbar {
    /* padding: 0px; */
    display: flex;
    align-items: center;
    /* max-width: 600px; */
    /* position: relative; */
    /* position: absolute; */
    flex-wrap: wrap;
    width: 50%;
    margin: 0 auto;
    border-bottom: 1px solid #f2f2f2;
}

.navbar h1 {
    color: #dda15e;
}

.navbar .links {
    margin-left: auto;
    /* overflow: auto; */
    /* display: flex; */

}

.navbar .links .link-skills {
    color: #14213d;
    background-color: #dda15e;
    border-radius: 5px;
}

.navbar a {
    margin-left: 16px;
    text-decoration: none;
    padding: 6px;
    color: #fefae0;
}

.navbar a:hover {
    color: #dda15e;
}

@media screen and (max-width: 1000px) {

    .navbar {
        padding-bottom: 25px;
        border-bottom: 1px solid #f2f2f2;
    }

    .navbar a {
        text-decoration: none;
        padding: 6px;
        color: #fefae0;
    }    
}

@media screen and (max-width: 480px) {

    .navbar {
        padding-bottom: 30px;
        /* border-bottom: 1px solid red; */
    }

    .navbar .links {
        display: grid;
        margin-right: auto;
        background-color: #dda15e;
        border-radius: 5px;

    }

    .navbar a {
        margin: auto;
        /* font-size: 20px; */
        size: 20px;
        padding: 5px;
        color: #14213d;
        /* width: 60px; */
        /* border-bottom: 1px solid black; */
    }

    .navbar .links .link-skills {
        /* margin: auto; */
        display: flex;
    }
}