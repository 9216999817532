.login {
    width: 100%;
}

.login .content-login {
    padding: 10px;
    margin: 0 auto;
    margin-top: 7%;
    width: 400px;
    background-color: white;
    border-radius: 4px;
    border: 1px solid black;
    text-align: center;
    -webkit-box-shadow: 0 0 4px 2px rgba(0, 0, 0, .2);
    -moz-box-shadow: 0 0 4px 2px rgba(0, 0, 0, .2);
    box-shadow: 0 0 4px 2px rgba(0, 0, 0, .2);
}

.login .content-login .content-login__form {
    margin-top: 10%;
    margin-bottom: 5%;
    margin-right: 10%;
    margin-left: 10%;
    padding: 10px;
}

.login .content-login .content-login__form h1 {
    font-size: 36px;
    /* color: blue; */
}