@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

/* base styles */

.html,
body {
    /* margin: 0; */
    font-family: "Quicksand";
    justify-content: center;
    color: #fefae0;
    background-color: #14213d;
    position: relative;
    /* margin: 0;
    padding: 0;
    height: 100%;
    padding-bottom: 100px; */
    /* padding-right: 70px; */
}


.content {
    /* max-width: 70%; */
    display: flex;
    padding: 50px;
    /* padding-right: 110px; */
    /* min-width: 50px; */
    /* max-height: 800px; */
    /* max-width: 100px; */

    margin: 0 auto;
}