.skill-card-grid {
    display: flex;
    padding: 10px;
    margin: 0 auto;
    width: 80%;
    background-color: #223766;
    border-radius: 4px;
    flex-wrap: wrap;
    border: 1px solid black;
    -webkit-box-shadow: 0 0 4px 2px rgba(0, 0, 0, .2);
    -moz-box-shadow: 0 0 4px 2px rgba(0, 0, 0, .2);
    box-shadow: 0 0 4px 2px rgba(0, 0, 0, .2);
}

.skill-card {
    color: #fefae0;
    background-color: #14213d;
    margin: 1% 2%;
    margin-left: auto;
    margin-right: auto;
    padding: 5px 0px;
    padding-bottom: 20px;
    min-inline-size: 200px;
    border-radius: 6px;
    /* border: 1px solid #fafafa; */
    overflow: auto;
    /* box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.5); */
}

.skill-card .skill-card-title {
    font-size: 11px;
    padding-bottom: 5px;
    padding-top: 2px;
    color: #dda15e;
    text-align: center;
}

.skill-card .skill-card-description {
    /* width: 90%; */
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 20px;
    text-align: center;
}

.skill-card .skill-card-title-separator {
    align-self: center;
    margin: 0px auto;
    border-bottom: 1px solid #fafafa;
    width: 70%;
}

.skill-card-children {
    /* padding-top: 20px; */
    padding-left: 5%;
}

.skill-card-children-text {
    padding-top: 5px;
    font-size: 14px;
}

.skill-card .skill-card-more {
    width: 90%;
    height: 30px;
    /* border: 1px solid black;
    background-color: #3b9e2d;
    border-radius: 4px;
    font-size: 15px; */
}


/* Dealing with hover */

.skill-card:hover {
    color: #14213d;
    background-color: #dda15e;
    box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.6);
}

.skill-card:hover h1 {
    color: #14213d;
}

.skill-card:hover .skill-card-title-separator {
    border-bottom: 1px solid #14213d;
}


/* .skill-card .skill-card-more:hover {
    color: #fafafa;
    background-color: #14213d;
} */