.about {
    display: flex;
    /* margin: auto;
    max-width: 100%; */
    padding: 10px;
    margin: 0 auto;
    /* margin-top: 7%; */
    width: 80%;
    background-color: #223766;
    font: black;
    /* background-color: #f1f3f5; */
    border-radius: 4px;
    flex-wrap: wrap;
    border: 1px solid black;
    align-self: center;
    /* text-align: center; */
    -webkit-box-shadow: 0 0 4px 2px rgba(0, 0, 0, .2);
    -moz-box-shadow: 0 0 4px 2px rgba(0, 0, 0, .2);
    box-shadow: 0 0 4px 2px rgba(0, 0, 0, .2);
}

.about-content {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    justify-content: center;
}